<template>
    <div></div>
</template>

<script>
    import getContractApi from '../api/contract'
    export default {
        name: "WineNFTDatas",
        created() {
            this.market = getContractApi().getWineMarket()
            // 여기서 와인 리스트 가져와서 프로퍼티 정보랑 같이 DB에 넣어줍니다.
            // 그리고 해당 API를 주석처리 해서 실제 서버에서는 구동되지 않게끔 처리 합니다.
            this.market.fetchTotalSupply().then(count => {
                console.dir(count.toNumber()) // 총 23개

                for(let i = 1; i <= count.toNumber(); i++) {
                /*for(let i = 1; i <= 10; i++) {*/
                    this.market.fetchItemInfoByTokenId(i).then(data => {
                        console.dir(data)
                        this.$store.dispatch('phpNFT/setWineNFT', data).then((returnData) => {
                            console.dir(returnData.data.nft_key + "==>")
                            console.dir(returnData.data)
                        }).catch(() => {
                            alert('와인 등록중 오류가 발생하였습니다.')
                        })

                    }).catch(err => {
                        console.log("err ::")
                        console.dir(err)
                    })
                }
            })
        },
        data() {
            return {
                market:null
            }
        }
    }
</script>

<style scoped>

</style>